<template>
    <div class="px-2 space-y-1">
        <section>
            <div class="bg-gray-800 rounded-lg shadow">
                <div class="pt-1 pl-4 pr-4 pb-4">
                    <div class="sensor-group mt-4">
                        <label for="about" class="block text-sm font-medium text-white">
                            Main Building Name
                        </label>
                        <div class="relative flex items-start">
                            <div class="flex items-center h-5">
                                <input @change="handleResetSensorsSelectList" :checked="!selected_sensors.length" id="sensor" name="sensor" type="checkbox" class="h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="comments" class="font-medium text-white">All Areas</label>
                            </div>
                        </div>
                    </div>

                    <div v-for="sensorGroup in getSensors" :key="sensorGroup.name" class="sensor-group mt-4">
                        <label for="about" class="block text-sm font-medium text-white">
                            {{ sensorGroup.name }}
                        </label>
                        <div v-for="sensor in sensorGroup.sensors" :key="sensor.sensor_identifier" class="relative flex items-start">
                            <div class="flex items-center h-5">
                                <input @change="handleSensorSelect()" :value="sensor.sensor_identifier" v-model="selected_sensors" id="sensor" name="sensor" type="checkbox" class="h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="comments" class="font-medium text-white">{{ sensor.sensor_label }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    data: function() {
        return {
            selected_sensors: [],
            filters: {}
        }
    },
    computed: {
        ...mapGetters([
            'getSensors',
            'getFilters'
        ])
    },
    async created() {
        this.filters = this.getFilters;
        this.selected_sensors = [];
        await this.$store.dispatch('fetchSensors');
    },
    methods: {
        ...mapMutations([
            'setFilters',
        ]),
        handleResetSensorsSelectList() {
            this.selected_sensors = [];
            this.filters.sensors = this.selected_sensors;
            this.setFilters(this.filters); 
        },
        handleSensorSelect() {
            this.filters.sensors = this.selected_sensors;
            this.setFilters(this.filters);
        }
    }
}
</script>