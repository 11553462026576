<template>
    <div class="flex space-x-4 overflow-x-auto">
        <div>
            <date-picker 
                v-model="filter_by_date" 
                type="date" 
                range 
                placeholder="Filter By Date Range"
                value-type="format"
                format="YYYY-MM-DD"
                :editable="false"
                :clearable="false"
                @change="handleDatePickerChange"
                :disabled="filters.time_window !== 'custom'"
                :input-class="'mx-input relative z-0 inline-flex shadow-sm rounded-md bg-gray-800 text-white border-0'" />
            </div>
        <div>
            <span class="relative z-0 inline-flex shadow-sm rounded-md bg-gray-800">
                <button @click="groupByTimeWindow('hour')" :class="filters.time_window === 'hour' ? 'bg-gray-600': 'bg-gray-800'" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Hour
                </button>
                <button @click="groupByTimeWindow('day')" :class="filters.time_window === 'day' ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Day
                </button>
                <button @click="groupByTimeWindow('week')" :class="filters.time_window === 'week' ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Week
                </button>
                <button @click="groupByTimeWindow('month')" :class="filters.time_window === 'month' ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Month
                </button>
                <button @click="groupByTimeWindow('custom')" :class="filters.time_window === 'custom' ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Custom
                </button>
            </span>
        </div>

        <div>
            <span class="relative z-0 inline-flex shadow-sm rounded-md bg-gray-800">
                <button @click="enableSensorInOut('in')" :class="filters.sensor_in ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative rounded-l-md inline-flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    In
                </button>
                <button @click="enableSensorInOut('out')" :class="filters.sensor_out ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Out
                </button>
            </span>
        </div>

        <div>
            <span class="relative z-0 inline-flex shadow-sm rounded-md bg-gray-800 whitespace-nowrap">
                <button @click="toggleWeather" :class="filters.weather ? 'bg-gray-600': 'bg-gray-800'" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    Weather
                </button>
                <button @click="toggleCovid" :class="filters.covid ? 'bg-gray-600': 'bg-gray-800'" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm font-medium text-white hover:bg-gray-700 focus:z-10 focus:outline-none">
                    COVID #s
                </button>
            </span>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        computed: {
            ...mapGetters([
                'getFilters'
            ])
        },
        data() {
            return {
                filter_by_date: [],
                filters: {},
                momentFormat: {
                    stringify: (date) => {
                        return date ? moment(date).format('LL') : ''
                    },
                    parse: (value) => {
                        return value ? moment(value, 'LL').toDate() : null
                    },
                }
            }
        },
        created() {
            this.filters = this.getFilters;
            this.filter_by_date = [this.filters.from_date, this.filters.to_date];
        },
        components: {
            DatePicker
        },
        methods: {
            ...mapMutations([
                'setFilters',
            ]),
            handleDatePickerChange() {
                this.filters.from_date = this.filter_by_date[0];
                this.filters.to_date = this.filter_by_date[1];
                this.setFilters(this.filters);
            },
            groupByTimeWindow(timeWindow) {
                this.filters.time_window = timeWindow;
                this.setFilters(this.filters);
            },
            enableSensorInOut(sensorType) {
                switch(sensorType) {
                    case 'in':
                        this.filters.sensor_in = true;
                        this.filters.sensor_out = false;
                        break;
                    case 'out':
                        this.filters.sensor_in = false;
                        this.filters.sensor_out = true;
                        break;
                }

                this.setFilters(this.filters);
            },
            toggleWeather() {
                this.filters.weather = !this.filters.weather;
                this.setFilters(this.filters);
            },
            toggleCovid() {
                this.filters.covid = !this.filters.covid;
                this.setFilters(this.filters);
            },
        }
    }
</script>
<style>
.mx-datepicker-range {
    width: 250px;
}
.mx-input {
    height: 36px;
}
</style>