<template>
    <div class="digital-signage">
        <div v-if="areaName != null" class="digital-signage-top flex items-center text-white">
            <div class="flex w-full items-center">
                <div class="flex-1 px-4 w-1/4">
                    <h4 class="text-5xl font-bold">{{ areaTitle }}</h4>
                    <p class="my-1 text-xl">{{ totalOccupancyPercent }}% occupancy</p>
                </div>
                <div class="flex-none px-4 w-3/4 h-full digital-signage-chart-wrapper">
                    <div class="horizontal-bar-chart">
                        <div class="horizontal-bar-chart-line" :style="{width: `${totalOccupancyPercent}%`, background: chartDataBackgroundColor}"></div>
                        <div class="horizontal-bar-chart-value">
                            {{ totalOccupancyPercent }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DSSparkLineChart',
        data: function() {
            return {
                chartDataBackgroundColor: '#0dc76c',
                websiteUrl: process.env.VUE_APP_WEBSITE_URL,
                areaName: null,
                areaNameFr: null,
                areaTitle: null,
                areaTitleFr: null,
                totalOccupancyPercent: 0,
                totalPlacesLeftPercent: 0,
                data: [0, 100]
            }
        },
        async created() {
            this.fetchOccupancy(this.$route.params.sensor_group);

            setInterval(function () {
                this.fetchOccupancy(this.$route.params.sensor_group);
            }.bind(this), 10000); 
        },
        methods: {
            async fetchOccupancy(sensor_group) {
                await this.$api.get(`/area-capacity/${sensor_group}/`)
                    .then((response) => {
                        this.areaName = response.data.name;
                        this.areaNameFr = response.data.name_fr;
                        this.areaTitle = response.data.title;
                        this.areaTitleFr = response.data.title_fr;

                        if(response.data.capacity >= 100) {
                            this.chartDataBackgroundColor = '#af1313';
                            this.totalOccupancyPercent = 100;
                            this.totalPlacesLeftPercent = 0;
                        } else {
                            if(response.data.capacity >= 80 && response.data.capacity < 100) {
                                this.chartDataBackgroundColor = '#d2b23e';
                            }

                            this.totalOccupancyPercent = response.data.capacity;
                            this.totalPlacesLeftPercent = 100 - this.totalOccupancyPercent;
                        }
                    });
            }
        }
    }
</script>
<style scoped>
    .digital-signage {
        width: 1080px;
    }
    .digital-signage h4 {
        font-family: 'BebasBold';
    }
    .digital-signage p {
        font-family: 'BebasLight';
    }
    .digital-signage-top {
        height: 200px;
    }
    .digital-signage-chart-wrapper {
        position: relative;
    }
    .digital-signage-chart-value {
        font-family: 'Open Sans';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
    }

    .horizontal-bar-chart {
        position: relative;
        width: 100%;
        height: 100px;
        background: #555555;
    }
    .horizontal-bar-chart-line {
        position: absolute;
        top: 0;
        left: 0;
        height: 100px;
        z-index: 1;
    }
    .horizontal-bar-chart-value {
        position: relative;
        position: absolute;
        z-index: 2;
        padding: 30px 0;
        width: 100%;
        text-align: center;
        top: 0;
        left: 0;
        color: white;
        font-size: 30px;
        font-weight: bold;
    }
</style>