import Vue from 'vue';
import VueRouter from 'vue-router';
import SignIn from '../views/SignIn.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Dashboard from '../views/Dashboard.vue';
import DigitalSignageLineChart from '../views/DSSparkLineChart.vue';
import DigitalSignageCircleChart from '../views/DSSparkCircleChart.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    component: SignIn,
    meta: {
      guard: false
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      guard: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      guard: false
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'page-with-navigation',
      guard: true,
    }
  },
  {
    path: '/digital-signage-line-chart/:sensor_group',
    name: 'DSSparkLineChart',
    component: DigitalSignageLineChart,
    meta: {
      guard: false
    }
  },
  {
    path: '/digital-signage-circle-chart/:sensor_group',
    name: 'DSSparkCircleChart',
    component: DigitalSignageCircleChart,
    meta: {
      guard: false
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  if ((to.meta.guard === true) && localStorage.getItem('token') === null) next({ name: 'Signin' })
  else next()
})

export default router;
