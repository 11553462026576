<template>
    <div class="digital-signage">
        <div v-if="areaName != null" class="digital-signage-top flex items-center text-white">
            <div class="flex w-full items-center">
                <div class="flex-1 px-4 w-7/12">
                    <h4 class="text-5xl font-bold">{{ areaTitle }}</h4>
                    <p class="my-1 text-xl">{{ totalOccupancyPercent }}% occupancy</p>
                </div>
                <div class="flex-none px-4 w-5/12 h-full digital-signage-chart-wrapper">
                    <DoughnutChart
                        :chart-data="chartData"
                        :options="options">
                    </DoughnutChart>
                    <span class="digital-signage-chart-value">{{ totalOccupancyPercent }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import DoughnutChart from '../components/charts/DoughtnutChart';

    export default {
        name: 'DigitalSignage',
        components: { DoughnutChart },
        data: function() {
            return {
                websiteUrl: process.env.VUE_APP_WEBSITE_URL,
                areaName: null,
                areaNameFr: null,
                areaTitle: null,
                areaTitleFr: null,
                totalOccupancyPercent: 0,
                totalPlacesLeftPercent: 0,
                options: {
                    responsive: true, 
                    maintainAspectRatio: true,
                    cutoutPercentage: 80,
                    borderWidth: 0,
                    animation: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }
                }, 
                chartData: {
                    datasets: [{
                        backgroundColor: ['#0dc76c', '#555555'],
                        data: [0, 100]
                    }]
                }
            }
        },
        async created() {
            this.fetchOccupancy(this.$route.params.sensor_group);

            setInterval(function () {
                this.fetchOccupancy(this.$route.params.sensor_group);
            }.bind(this), 10000); 
        },
        methods: {
            async fetchOccupancy(sensor_group) {
                await this.$api.get(`/area-capacity/${sensor_group}/`)
                    .then((response) => {
                        this.areaName = response.data.name;
                        this.areaNameFr = response.data.name_fr;
                        this.areaTitle = response.data.title;
                        this.areaTitleFr = response.data.title_fr;

                        let chartDataBackgroundColor = '#0dc76c';

                        if(response.data.capacity >= 100) {
                            chartDataBackgroundColor = '#af1313';
                            this.totalOccupancyPercent = 100;
                            this.totalPlacesLeftPercent = 0;
                        } else {
                            if(response.data.capacity >= 80 && response.data.capacity < 100) {
                                chartDataBackgroundColor = '#d2b23e';
                            }

                            this.totalOccupancyPercent = response.data.capacity;
                            this.totalPlacesLeftPercent = 100 - this.totalOccupancyPercent;
                        }

                        this.chartData = {
                            datasets: [{
                                backgroundColor: [chartDataBackgroundColor, '#555555'],
                                data: [this.totalOccupancyPercent, this.totalPlacesLeftPercent]
                            }]
                        }
                    });
            }
        }
    }
</script>
<style scoped>
    .digital-signage {
        width: 540px;
    }
    .digital-signage h4 {
        font-family: 'BebasBold';
    }
    .digital-signage p {
        font-family: 'BebasLight';
    }
    .digital-signage-top {
        height: 200px;
    }
    .digital-signage-chart-wrapper {
        position: relative;
    }
    .digital-signage-chart-value {
        font-family: 'BebasBold';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
    }
</style>