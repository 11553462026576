<template>
  <div class="mt-8">
    <div class="w-full mx-auto px-4 sm:px-6 lg:px-8">
      <dashboard-filters />
    </div>
    <div class="w-full mx-auto mt-2 px-4 sm:px-6 lg:px-8">
      <div class="bg-gray-800 overflow-hidden shadow rounded-lg p-4">
        <dashboard-line-chart 
          v-if="loaded"
          :chart-data="getDashboardData" />

        <ul class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
          <li class="relative col-span-1 flex shadow-sm rounded-md">
            <div class="flex-shrink-0 flex items-center justify-center w-16 bg-pink-600 text-white text-lg font-medium rounded-l-md">
              {{getDashboardDataCount}}
            </div>
            <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class="text-gray-900 font-medium hover:text-gray-600">
                  Total Count
                </span>
              </div>
            </div>
          </li>
        </ul>

        
      </div>
    </div>
    <div v-if="getDashboardStats" class="w-full mx-auto pt-2 px-4 sm:px-6 lg:px-8">
      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <dashboard-stat-card :title="'Total Daily Visitors'" :value="cleanedStatValue(getDashboardStats.total_visitors, '')" :color="statColor(getDashboardStats.total_visitors)" />
        <dashboard-stat-card :title="'WoW'" :value="cleanedStatValue(getDashboardStats.week_over_week, '%')" :color="statColor(getDashboardStats.week_over_week)" />
        <dashboard-stat-card :title="'MoM'" :value="cleanedStatValue(getDashboardStats.month_over_month, '%')" :color="statColor(getDashboardStats.month_over_month)" />
        <dashboard-stat-card :title="'YoY'" :value="cleanedStatValue(getDashboardStats.year_over_year, '%')" :color="statColor(getDashboardStats.year_over_year)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DashboardFilters from '../components/DashboardFilters';
import DashboardStatCard from '../components/DashboardStatCard';
import DashboardLineChart from '../components/charts/DashboardLineChart.vue';

export default {
  name: 'Dashboard',
  data() {
    return {
      filters: [],
      chartData: [], 
      loaded: false
    };
  },
  computed: {
    ...mapGetters([
        'getFilters',
        'getDashboardData',
        'getDashboardStats',
        'getDashboardDataCount'
    ]),
  },
  components: {
    DashboardFilters,
    DashboardLineChart,
    DashboardStatCard,
  },
  async created() {
    this.filters = this.getFilters;
    this.loaded = false;

    await this.fetchDashboardData(this.filters);
    await this.fetchDashboardStats();

    this.loaded = true;
  },
  watch: {
    '$store.state.filters': {
        deep: true,
        async handler(newFilters) {
          await this.fetchDashboardData(newFilters);
        }
     }
  },
  methods: {
    async fetchDashboardData(filters) {
      await this.$store.dispatch('fetchDashboardData', filters);
    },
    async fetchDashboardStats() {
      await this.$store.dispatch('fetchDashboardStats');
    },
    cleanedStatValue(stat, stat_type) {
      if(!stat) {
        return 'N/A';
      }

      return stat + stat_type;
    },
    statColor(stat) {
      if(!stat) {
        return '#ffffff';
      }

      return stat >= 0 ? '#49af28' : '#49af28';
    }
  }
}
</script>